import { matchRoutes, useLocation } from 'react-router-dom';

const routes = [
    { path: '/' },
    { path: '/infra/' },
    { path: '/infra/home' },
    { path: '/infra/platform' },
    { path: '/infra/platform/mongo' },
    { path: '/infra/platform/sentry' },
    { path: '/infra/info' },
    { path: '/infra/info/metabase-metadata' },
    { path: '/infra/events' },
    { path: '/infra/events/mongo-db-events' },
    { path: '/infra/events/elastic-events' },
    { path: '/infra/events/gcp-incidents' },
    { path: '/infra/events/reblaze-dashboard'},
    { path: '/infra/infrastructure' },
    { path: '/infra/infrastructure/gcp-resource-utilization' },
    { path: '/infra/infrastructure/gcp-log-metrics' },
    { path: '/infra/observability' },
    { path: '/infra/observability/bigquery-table-deletion-info' },
    { path: '/infra/observability/bigquery-table-usage-info' },
    { path: '/infra/observability/bigquery-table-storage-analysis' },
    { path: '/infra/observability/query-log-threshold-exceeded-info' },
    { path: '/infra/observability/metabase-queries-info' },
    { path: '/infra/observability/bigquery-analysis-cost-comparator' },
    { path: '/infra/costing' },
    { path: '/infra/costing/gcp-costing' },
    { path: '/infra/costing/unit-costing' },
    { path: '/infra/costing/total-costing' },
    { path: '/infra/costing/load-vs-gcp-cost' },
    { path: '/infra/tbas' },
    { path: '/infra/tbas/mongo' },
    { path: '/infra/tbas/elastic' },
    { path: '/infra/user-info' },
    { path: '/infra/user-info/support' },
    { path: '/infra/administration' },
    { path: '/infra/administration/users-iam' },
    { path: '/infra/administration/mongo-tagging' },
    { path: '/infra/administration/elastic-tagging' },
    { path: '/infra/administration/bigquery-analysis-config' },
    { path: '/infra/audit'},
    { path: '/infra/audit/pii-data-download' },
    { path: '/infra/audit/audit-pii-data-download' },
    { path: '/infra/audit/otp-users' },
    { path: '/infra/audit/malicious-user-download' },  
    { path: '/infra/audit/blacklist-reset-user' },
    { path: '/security/' },
    { path: '/security/home' },
    { path: '/security/team' },
    { path: '/security/wazuh' },
    { path: '/security/controlpanel' },
    { path: '/security/policies' },
    { path: '/access/' },
    { path: '/access/tools' },
    { path: '/access/admin' },
    { path: '/access/admin/usermanagement' },
    { path: '/access/admin/toolmanagement' },
    { path: '/access/admin/dashboard' },
    { path: '/access/manager' },
    { path: '/access/manager/pendingrequest' },
    { path: '/access/manager/allotteam' },
    { path: '/access/manager/dashboard' },
    { path: '/access/apnatechstack' },
];

const useCurrentPath = () => {
    const location = useLocation();
    const [{ route }] = matchRoutes(routes, location);
    return route.path;
};

export default useCurrentPath;
