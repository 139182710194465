import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import useCurrentPath from '../../hooks/useCurrentPath';
import { useAuth } from '../../contexts/auth';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import Drawer from '@mui/material/Drawer';
import BackgroundLetterAvatars from '../../utils/avatar';
import { styled, alpha } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import InputBase from '@mui/material/InputBase';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupsIcon from '@mui/icons-material/Groups';
import InsightsIcon from '@mui/icons-material/Insights';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import DD2 from '../DD2';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import SecurityIcon from '@mui/icons-material/Security';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import ArticleIcon from '@mui/icons-material/Article';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PolicyIcon from '@mui/icons-material/Policy';
import { useNavigate } from 'react-router-dom';
import Profile from '../accessComponents/profile';
import Notification from '../accessComponents/notification';
import ConstructionIcon from '@mui/icons-material/Construction';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
const InfraNavigation = [
    {
        name: 'Home',
        href: '/infra/home',
        icon: HomeIcon,
        adminOnly: false,
    },
    {
        name: 'Platform',
        href: '/infra/platform',
        icon: ViewInArIcon,
        children: [
            {
                name: 'Mongo DB',
                href: '/infra/platform/mongo',
                ownerOnly: false,
            },
            {
                name: 'Sentry',
                href: '/infra/platform/sentry',
                ownerOnly: false,
            },
        ],
        adminOnly: false,
    },
    {
        name: 'Info',
        href: '/infra/info',
        icon: InfoIcon,
        children: [
            {
                name: 'Metabase Metadata',
                href: '/infra/info/metabase-metadata',
                ownerOnly: false,
            },
        ],
        adminOnly: false,
    },
    {
        name: 'Events',
        href: '/infra/events',
        icon: InsightsIcon,
        children: [
            {
                name: 'Mongo DB Events',
                href: '/infra/events/mongo-db-events',
                ownerOnly: false,
            },
            {
                name: 'Elastic Events',
                href: '/infra/events/elastic-events',
                ownerOnly: false,
            },
            {
                name: 'GCP Incidents',
                href: '/infra/events/gcp-incidents',
                ownerOnly: false,
            },
            {
                name: 'Reblaze Dashboard',
                href: '/infra/events/reblaze-dashboard',
                ownerOnly: false,
            },
        ],
        adminOnly: false,
    },
    {
        name: 'Infrastructure',
        href: '/infra/infrastructure',
        icon: SettingsIcon,
        children: [
            {
                name: 'GCP Resource Utilization',
                href: '/infra/infrastructure/gcp-resource-utilization',
                ownerOnly: false,
            },
            {
                name: 'GCP Log Metrics',
                href: '/infra/infrastructure/gcp-log-metrics',
                ownerOnly: false,
            },
        ],
        adminOnly: false,
    },
    {
        name: 'Observability',
        href: '/infra/observability',
        icon: VisibilityIcon,
        children: [
            {
                name: 'Bigquery Table Deletion',
                href: '/infra/observability/bigquery-table-deletion-info',
                ownerOnly: false,
            },
            {
                name: 'Bigquery Table Usage',
                href: '/infra/observability/bigquery-table-usage-info',
                ownerOnly: false,
            },
            {
                name: 'Exceeded Bytes Query',
                href: '/infra/observability/query-log-threshold-exceeded-info',
                ownerOnly: false,
            },
            {
                name: 'Metabase Queries',
                href: '/infra/observability/metabase-queries-info',
                ownerOnly: false,
            },
            {
                name: 'Bigquery Storage Cost',
                href: '/infra/observability/bigquery-table-storage-analysis',
                ownerOnly: false,
            },
            {
                name: 'Bigquery Analysis Cost Comparator',
                href: '/infra/observability/bigquery-analysis-cost-comparator',
                ownerOnly: false,
            },
        ],
        adminOnly: false,
    },
    {
        name: 'Costing',
        href: '/infra/costing',
        icon: AttachMoneyIcon,
        children: [
            {
                name: 'GCP Costing',
                href: '/infra/costing/gcp-costing',
                ownerOnly: false,
            },
            {
                name: 'Unit Costing',
                href: '/infra/costing/unit-costing',
                ownerOnly: false,
            },
            {
                name: 'Total Costing',
                href: '/infra/costing/total-costing',
                ownerOnly: false,
            },
            {
                name: 'Load vs GCP Cost',
                href: '/infra/costing/load-vs-gcp-cost',
                ownerOnly: false,
            },
        ],
        adminOnly: false,
    },
    {
        name: 'Tbas',
        href: '/infra/tbas',
        icon: AccessAlarmsIcon,
        children: [
            { name: 'Mongo Tbas', href: '/infra/tbas/mongo', ownerOnly: false },
            {
                name: 'Elastic Tbas',
                href: '/infra/tbas/elastic',
                ownerOnly: false,
            },
        ],
        adminOnly: false,
    },
    {
        name: 'Support Corner',
        href: '/infra/user-info',
        icon: PersonOutlineIcon,
        children: [
            {
                name: 'User Help',
                href: '/infra/user-info/support',
                ownerOnly: false,
            },
        ],
        adminOnly: false,
    },
    {
        name: 'Administration',
        href: '/infra/administration',
        icon: AdminPanelSettingsIcon,
        children: [
            {
                name: 'Users IAM',
                href: '/infra/administration/users-iam',
                ownerOnly: false,
            },
            {
                name: 'Mongo Tagging',
                href: '/infra/administration/mongo-tagging',
                ownerOnly: false,
            },
            {
                name: 'Elastic Tagging',
                href: '/infra/administration/elastic-tagging',
                ownerOnly: false,
            },
            {
                name: 'Bigquery Analysis Config',
                href: '/infra/administration/bigquery-analysis-config',
                ownerOnly: false,
            },
        ],
        adminOnly: true,
    },
    {
        name: 'Audit',
        href: '/infra/audit',
        icon: LockPersonIcon,
        children: [
            {
                name: 'PII Data Download',
                href: '/infra/audit/pii-data-download',
                ownerOnly: false,
            },
            {
                name: 'Audit PII Data Download',
                href: '/infra/audit/audit-pii-data-download',
                ownerOnly: true,
            },
            {
                name: 'Malicious User Data Download',
                href: '/infra/audit/malicious-user-download',
                ownerOnly: false,
            },
            {
                name: 'Blacklist/Reset User',
                href: '/infra/audit/blacklist-reset-user',
                ownerOnly: false,
            },
            {
                name: 'Otp User',
                href: '/infra/audit/otp-users',
                ownerOnly: false,
            },
        ],
        piiDataViewerOnly: true,
    },
];
const securityNavigation = [
    {
        name: 'Home',
        href: '/security/home',
        icon: HomeIcon,
        securityAdminOnly: false,
    },
    {
        name: 'Team',
        href: '/security/team',
        icon: GroupsIcon,
        securityAdminOnly: false,
    },
    {
        name: 'Wazuh',
        href: '/security/wazuh',
        icon: SecurityIcon,
        securityAdminOnly: false,
    },
    {
        name: 'Control Panel',
        href: '/security/controlpanel',
        icon: ControlCameraIcon,
        securityAdminOnly: true,
    },
    {
        name: 'Policies',
        href: '/security/policies',
        icon: PolicyIcon,
        securityAdminOnly: false,
    },
];
const AccessNavigation = [
    {
        name: 'Tools',
        href: '/access/tools',
        icon: ConstructionIcon,
        adminOnly: false,
    },
    {
        name: 'Admin',
        href: '/access/admin',
        icon: AdminPanelSettingsIcon,
        children: [
            {
                name: 'User Management',
                href: '/access/admin/usermanagement',
                itAdminOnly: true,
            },
            {
                name: 'Tool Management',
                href: '/access/admin/toolmanagement',
                itAdminOnly: true,
            },
            {
                name: 'Dashboard',
                href: '/access/admin/dashboard',
                itAdminOnly: true,
            },
        ],
        itAdminOnly: true,
    },
    {
        name: 'Manager',
        href: '/access/manager',
        icon: ManageAccountsIcon,
        children: [
            {
                name: 'Pending Request',
                href: '/access/manager/pendingrequest',
                managerOnly: false,
            },
            {
                name: 'Allot Team',
                href: '/access/manager/allotteam',
                managerOnly: false,
            },
            {
                name: 'Dashboard',
                href: '/access/manager/dashboard',
                managerOnly: false,
            },
        ],
        managerOnly: true,
    },
    {
        name: 'Apna Tech Stack',
        href: '/access/apnatechstack',
        icon: IntegrationInstructionsIcon,
        adminOnly: false,
    },
];
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const isCurrentPath = (path, value) => {
    return path === value;
};

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const Sidebar = () => {
    const nav = useNavigate();
    const dict = {
        infra: { name: 'Infrastructure', id: 'infra' },
        security: { name: 'Security', id: 'security' },
        access: { name: 'Access', id: 'access' },
    };
    const [menuType, setMenuType] = React.useState(
        dict[
            localStorage.getItem('sidebar')
                ? localStorage.getItem('sidebar')
                : 'infra'
        ]
    );
    const menuTypes = [
        { name: 'Infrastructure', id: 'infra' },
        { name: 'Security', id: 'security' },
        { name: 'Access', id: 'access' },
    ];
    const currentPath = useCurrentPath();
    const {
        isAdmin,
        isOwner,
        user,
        isPIIDataViewer,
        invalidateUser,
        isSecurityAdmin,
        isItadmin,
        isManager,
    } = useAuth();
    var admin = isAdmin();
    var securityAdmin = isSecurityAdmin();
    var manager = isManager();
    var itAdmin = isItadmin();
    var owner = isOwner();
    var piiDataViewer = isPIIDataViewer();

    const handleSidebar = (item) => {
        setMenuType(item);
        localStorage.setItem('sidebar', item.id);
        if (item.id === 'infra') {
            return nav('/infra');
        } else if (item.id === 'security') {
            return nav('/security');
        } else if (item.id === 'access') {
            return nav('/access');
        }
    };

    const [state, setState] = React.useState({
        left: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const [list, setList] = React.useState(
        <Box
            sx={{
                width: 250,
                height: '100px',
                padding: 2,
            }}
            role="presentation"
        >
            <List>
                {InfraNavigation.map((item) =>
                    item.adminOnly && !admin ? (
                        <></>
                    ) : !item.children ? (
                        <>
                            <div key={item.name}>
                                <Link
                                    to={`${item.href}`}
                                    className={classNames(
                                        isCurrentPath(currentPath, item.href)
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                        'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                                    )}
                                >
                                    <item.icon
                                        className={classNames(
                                            isCurrentPath(
                                                currentPath,
                                                item.href
                                            )
                                                ? 'text-indigo-500'
                                                : 'text-gray-400 group-hover:text-gray-500',
                                            'mr-3 flex-shrink-0 h-6 w-6'
                                        )}
                                        aria-hidden="true"
                                    />
                                    {item.name}
                                </Link>
                            </div>
                        </>
                    ) : (
                        <Disclosure
                            as="div"
                            key={item.name}
                            className="space-y-1"
                        >
                            {({ open }) => (
                                <>
                                    <Disclosure.Button
                                        className={classNames(
                                            isCurrentPath(
                                                currentPath,
                                                item.href
                                            )
                                                ? 'bg-gray-100 text-gray-900'
                                                : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                            'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md'
                                        )}
                                    >
                                        <item.icon
                                            className={classNames(
                                                isCurrentPath(
                                                    currentPath,
                                                    item.href
                                                )
                                                    ? 'text-indigo-500'
                                                    : 'text-gray-400 group-hover:text-gray-500',
                                                'mr-3 flex-shrink-0 h-6 w-6'
                                            )}
                                            aria-hidden="true"
                                        />
                                        <span className="flex-1">
                                            {item.name}
                                        </span>
                                        <svg
                                            className={classNames(
                                                open
                                                    ? 'text-gray-400 rotate-90'
                                                    : 'text-gray-300',
                                                'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                            )}
                                            viewBox="0 0 20 20"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M6 6L14 10L6 14V6Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="space-y-1">
                                        {item.children.map((subItem) =>
                                            subItem.ownerOnly && !owner ? (
                                                <></>
                                            ) : (
                                                <Link
                                                    key={subItem.name}
                                                    to={`${subItem.href}`}
                                                    className={`${
                                                        isCurrentPath(
                                                            currentPath,
                                                            subItem.href
                                                        )
                                                            ? 'bg-gray-100 text-gray-900'
                                                            : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                                    } group w-full flex items-center pl-14 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md `}
                                                >
                                                    {subItem.name}
                                                </Link>
                                            )
                                        )}
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    )
                )}
            </List>
        </Box>
    );
    useEffect(() => {
        if (menuType.id === 'infra') {
            setList(
                <Box
                    sx={{
                        width: 250,
                        height: '100px',
                        padding: 2,
                    }}
                    role="presentation"
                >
                    <List>
                        {InfraNavigation.map((item) =>
                            item.adminOnly && !admin ? (
                                <></>
                            ) : item.piiDataViewerOnly && !piiDataViewer ? (
                                <></>
                            ) : !item.children ? (
                                <>
                                    <div key={item.name}>
                                        <Link
                                            to={`${item.href}`}
                                            className={classNames(
                                                isCurrentPath(
                                                    currentPath,
                                                    item.href
                                                )
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    isCurrentPath(
                                                        currentPath,
                                                        item.href
                                                    )
                                                        ? 'text-indigo-500'
                                                        : 'text-gray-400 group-hover:text-gray-500',
                                                    'mr-3 flex-shrink-0 h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </Link>
                                    </div>
                                </>
                            ) : (
                                <Disclosure
                                    as="div"
                                    key={item.name}
                                    className="space-y-1"
                                >
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button
                                                className={classNames(
                                                    isCurrentPath(
                                                        currentPath,
                                                        item.href
                                                    )
                                                        ? 'bg-gray-100 text-gray-900'
                                                        : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                    'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md'
                                                )}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        isCurrentPath(
                                                            currentPath,
                                                            item.href
                                                        )
                                                            ? 'text-indigo-500'
                                                            : 'text-gray-400 group-hover:text-gray-500',
                                                        'mr-3 flex-shrink-0 h-6 w-6'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                <span className="flex-1">
                                                    {item.name}
                                                </span>
                                                <svg
                                                    className={classNames(
                                                        open
                                                            ? 'text-gray-400 rotate-90'
                                                            : 'text-gray-300',
                                                        'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                                    )}
                                                    viewBox="0 0 20 20"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        d="M6 6L14 10L6 14V6Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="space-y-1">
                                                {item.children.map((subItem) =>
                                                    subItem.ownerOnly &&
                                                    !owner ? (
                                                        <></>
                                                    ) : (
                                                        <Link
                                                            key={subItem.name}
                                                            to={`${subItem.href}`}
                                                            className={`${
                                                                isCurrentPath(
                                                                    currentPath,
                                                                    subItem.href
                                                                )
                                                                    ? 'bg-gray-100 text-gray-900'
                                                                    : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                                            } group w-full flex items-center pl-14 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md `}
                                                        >
                                                            {subItem.name}
                                                        </Link>
                                                    )
                                                )}
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            )
                        )}
                    </List>
                </Box>
            );
        } else if (menuType.id === 'security') {
            setList(
                <Box
                    sx={{
                        width: 250,
                        height: '100px',
                        padding: 2,
                    }}
                    role="presentation"
                >
                    {securityNavigation.map((item) =>
                        item.securityAdminOnly && !securityAdmin ? (
                            <></>
                        ) : !item.children ? (
                            <>
                                <div key={item.name}>
                                    <Link
                                        to={`${item.href}`}
                                        className={classNames(
                                            isCurrentPath(
                                                currentPath,
                                                item.href
                                            )
                                                ? 'bg-gray-100 text-gray-900'
                                                : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                            'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                                        )}
                                    >
                                        <item.icon
                                            className={classNames(
                                                isCurrentPath(
                                                    currentPath,
                                                    item.href
                                                )
                                                    ? 'text-indigo-500'
                                                    : 'text-gray-400 group-hover:text-gray-500',
                                                'mr-3 flex-shrink-0 h-6 w-6'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <Disclosure
                                as="div"
                                key={item.name}
                                className="space-y-1"
                            >
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button
                                            className={classNames(
                                                isCurrentPath(
                                                    currentPath,
                                                    item.href
                                                )
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    isCurrentPath(
                                                        currentPath,
                                                        item.href
                                                    )
                                                        ? 'text-indigo-500'
                                                        : 'text-gray-400 group-hover:text-gray-500',
                                                    'mr-3 flex-shrink-0 h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span className="flex-1">
                                                {item.name}
                                            </span>
                                            <svg
                                                className={classNames(
                                                    open
                                                        ? 'text-gray-400 rotate-90'
                                                        : 'text-gray-300',
                                                    'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                                )}
                                                viewBox="0 0 20 20"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M6 6L14 10L6 14V6Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="space-y-1">
                                            {item.children.map((subItem) =>
                                                subItem.ownerOnly && !owner ? (
                                                    <></>
                                                ) : (
                                                    <Link
                                                        key={subItem.name}
                                                        to={`${subItem.href}`}
                                                        className={`${
                                                            isCurrentPath(
                                                                currentPath,
                                                                subItem.href
                                                            )
                                                                ? 'bg-gray-100 text-gray-900'
                                                                : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                                        } group w-full flex items-center pl-14 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md `}
                                                    >
                                                        {subItem.name}
                                                    </Link>
                                                )
                                            )}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        )
                    )}
                </Box>
            );
        } else {
            setList(
                <Box
                    sx={{
                        width: 250,
                        height: '100px',
                        padding: 2,
                    }}
                    role="presentation"
                >
                    {AccessNavigation.map((item) =>
                        (item.itAdminOnly && !itAdmin) ||
                        (item.managerOnly && !manager) ? (
                            <></>
                        ) : !item.children ? (
                            <>
                                <div key={item.name}>
                                    <Link
                                        to={`${item.href}`}
                                        className={classNames(
                                            isCurrentPath(
                                                currentPath,
                                                item.href
                                            )
                                                ? 'bg-gray-100 text-gray-900'
                                                : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                            'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                                        )}
                                    >
                                        <item.icon
                                            className={classNames(
                                                isCurrentPath(
                                                    currentPath,
                                                    item.href
                                                )
                                                    ? 'text-indigo-500'
                                                    : 'text-gray-400 group-hover:text-gray-500',
                                                'mr-3 flex-shrink-0 h-6 w-6'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <Disclosure
                                as="div"
                                key={item.name}
                                className="space-y-1"
                            >
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button
                                            className={classNames(
                                                isCurrentPath(
                                                    currentPath,
                                                    item.href
                                                )
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    isCurrentPath(
                                                        currentPath,
                                                        item.href
                                                    )
                                                        ? 'text-indigo-500'
                                                        : 'text-gray-400 group-hover:text-gray-500',
                                                    'mr-3 flex-shrink-0 h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span className="flex-1">
                                                {item.name}
                                            </span>
                                            <svg
                                                className={classNames(
                                                    open
                                                        ? 'text-gray-400 rotate-90'
                                                        : 'text-gray-300',
                                                    'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                                )}
                                                viewBox="0 0 20 20"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M6 6L14 10L6 14V6Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="space-y-1">
                                            {item.children.map((subItem) =>
                                                subItem.ownerOnly && !owner ? (
                                                    <></>
                                                ) : (
                                                    <Link
                                                        key={subItem.name}
                                                        to={`${subItem.href}`}
                                                        className={`${
                                                            isCurrentPath(
                                                                currentPath,
                                                                subItem.href
                                                            )
                                                                ? 'bg-gray-100 text-gray-900'
                                                                : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                                        } group w-full flex items-center pl-14 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md `}
                                                    >
                                                        {subItem.name}
                                                    </Link>
                                                )
                                            )}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        )
                    )}
                </Box>
            );
        }
    }, [menuType]);

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed" style={{ backgroundColor: '#4d3951' }}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={toggleDrawer('left', true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            href="https://apna.co/"
                            target="_blank"
                        >
                            <img
                                className="h-14 w-auto"
                                src="/assets/apna-time-icon.png"
                                alt="Apna"
                            />
                        </IconButton>

                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                        >
                            Unified Platform Services
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Search style={{ minWidth: '50vw' }}>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Will be enabled Soon…"
                                fullWidth
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                        <Box sx={{ flexGrow: 2 }} />
                        {menuType?.id === 'access' ? <Notification /> : <></>}
                        <Profile />
                    </Toolbar>
                </AppBar>
            </Box>

            <div>
                {['left'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                        >
                            <Box
                                sx={{
                                    width:
                                        anchor === 'top' || anchor === 'bottom'
                                            ? 'auto'
                                            : 250,
                                    height: '100px',
                                    padding: 2,
                                }}
                                role="presentation"
                            >
                                <DD2
                                    label={'Manage'}
                                    emptyLabel={'Select a Type'}
                                    options={menuTypes}
                                    value={menuType}
                                    onChange={handleSidebar}
                                    displayProperty={'name'}
                                    disabled={false}
                                />
                            </Box>

                            {list}
                        </Drawer>
                    </React.Fragment>
                ))}
            </div>
        </>
    );
};

export default Sidebar;
