import React from 'react';
import HeaderTitle from '../../../../components/typography/header-title';
import { Box } from '@mui/material';
import { useState } from 'react';
import OtpUserTable from '../../../../components/AuditPii/OtpUserTable';
const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};
const OtpUser = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <HeaderTitle
                title={'Get Otp users'}
                subtitle={'Get users data'}
            />
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                }}
            >
                <OtpUserTable/>
            </Box>
        </>
    );
};

export default OtpUser;
